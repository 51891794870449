import mainjson from './main';
export default function GetData() {
        return {
        "apitype":"imagelibrary",
        "id":"image_id",
        "subid":"image_folder_id",
        "options":["edit","insert","delete"],
        "nameSingle":"image",
        "nameMultiple":"images",
        "filters":
            [
               
            ]
        ,
        "fields":{
            "field1":{
                "name":"Name",
                "field":"image_name",
                "type":"TextInput",
                "required":true,
                "list":true,
            },
            "field2":{
                "name":"Image",
                "field":"image_file",
                "type":"ImageUpload",
                "list":true,
            },
            "field3":{
                "name":"Credits",
                "field":"image_credits",
                "type":"TextInput",
                "required":false,
                "list":true,
            },
            "field4":{
                "name":"Folder",
                "field":"image_folder_id",
                "type":"DropDown",
                "options": "folders",
                "defaultsubid":true,
                "required":false,
                
            },
            "field5":{
                "name":"Used in points",
                "field":"point_count",
                "type":"Display",
                "required":false,
                "list":true,
            },
            "field6":{
                "name":"Used in points",
                "field":"point_text",
                "type":"Display",
                "required":false,
                "list":false,
            },
            
        }
    }
    
}